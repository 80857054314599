<template>
  <div style="width: 1000px; max-width: 1000px !important">
    <c-table
      ref="table"
      title="사전검토 검토자 현황"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :isExcelDown="false"
      :filtering="false"
      :columnSetting="false"
      :hideBottom="true"
    >
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'review-status-proxy',
  props: {
    props: {
      type: Object,
      default: function() {
        return {
          row: [],
        }
      },
    },
    col: {
      type: Object,
      default: function() {
        return {}
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'mdmChemReviewClassName',
            field: 'mdmChemReviewClassName',
            label: '분야',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '검토자',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'mdmChemReviewResultName',
            field: 'mdmChemReviewResultName',
            label: '검토결과',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고',
            align: 'left',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'reviewDt',
            field: 'reviewDt',
            label: '검토일시',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          }
        ],
        data: [],
        height: '350px',
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.mdm.mam.review.check.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        mdmChemReviewId: this.props.row.mdmChemReviewId
      }
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
  }
};
</script>
